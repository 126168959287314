// Packages
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// Components
import Layout from '../components/Layout';
import Button from '../components/reusables/Button'
import HeadMetadata from '../components/HeadMetadata';

export default function Error404() {
    return (
        <Layout>
            <HeadMetadata title="Error 404"></HeadMetadata>
            <div className="error-section content-wrapper">
                <h1>OOPS!</h1>
                <h2>SOMETHING'S WRONG HERE</h2>
                <Button
                    text="BACK TO HOME"
                    link="/"
                    bg_color="platinum-bg"
                    fg_color="oxford-blue-fg"
                ></Button>
            </div>
        </Layout>
    );
}
// Packages
import React from 'react';
import { Link } from 'gatsby';

// Components



export default function Button({text, link, bg_color, fg_color}) {
    return (
        <div className={`generic-button ${bg_color}`}>
            <Link
                className={fg_color}
                to={link}
            >{text}</Link>
        </div>
    )
}